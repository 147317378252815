import CryptoJS from 'crypto-js';
import LocalStorageManager from 'managers/LocalStorageManger';
import storage from 'redux-persist/lib/storage';
import { getLandingURL, login } from 'routes/AppUrls';
import { persistor } from 'store';

export const availableLocales = ['en', 'ar'];

const secretKey =
  'Empowering Small and Medium Enterprises with customized financing solutions';

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export function isAuthenticated() {
  return LocalStorageManager.getItem('token');
}

export function getUserRole() {
  return LocalStorageManager.getItem('Role');
}

export const getDefaultLanguage = (local) => {
  // let browserLang = window.navigator.languages ? window.navigator.languages[0] : null;

  // browserLang = browserLang || window.navigator.language || window.navigator?.browserLanguage || window.navigator?.userLanguage;

  // let shortLang = browserLang;

  // if (shortLang?.indexOf("-") !== -1)
  //    shortLang = shortLang?.split("-")[0]

  // if (shortLang?.indexOf("_") !== -1)
  //    shortLang = shortLang?.split("_")[0]

  // shortLang = availableLocales.includes(shortLang) ? shortLang : local;

  return local;
};

export function injectString(str, index, stringToAdd) {
  /** Inject a string into another string. */
  return (
    str.substring(0, index) + stringToAdd + str.substring(index, str.length)
  );
}

export function PhoneNumberValidation(value) {
  const regex = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  return regex.test(value);
}
export function OnlyAlphabet(value) {
  const regex = /^[A-Za-z]+$/;
  return regex.test(value);
}

export function Emailverification(value) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(value);
}

export function PasswordValidation(value) {
  const regex =
    /^(?=.*[0-9])(?=.*[!@#$%^&*£])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*£]{8,16}$/;
  return regex.test(value);
}

export function PriceFomate(value) {
  if (isNaN(value)) {
    return '';
  }
  return Number(value)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function isJSONString(str) {
  const jsonPattern = /^\s*(\{.*\}|\[.*\])\s*$/;
  return jsonPattern.test(str);
}

export function checkAuthorization() {
  const storedToken = LocalStorageManager.getItem('token');

  if (storedToken) {
    const token = storedToken;

    // this just all works to compare the total seconds of the created
    // time of the token vs the ttl (time to live) seconds
    const createdDate = new Date(token.created);
    const created = Math.round(createdDate.getTime() / 1000);
    const ttl = 1209600;
    const expiry = created + ttl;

    // if the token has expired return false
    if (created > expiry) return false;

    return true;
  }

  return false;
}

export function IsJSONString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function ClearTokenRedirectLogin(navigate) {
  LocalStorageManager.removeItem('token');
  LocalStorageManager.removeItem('Role');
  LocalStorageManager.removeItem('registerToken');
  storage.removeItem('persist:persist-store');
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
  navigate(login('en'));
  window.location.reload();
}

export function screenScrollTop() {
  window.scrollTo({
    top: 500,
    behavior: 'smooth', // Optional, smooth scrolling animation
  });
}

export function checkClientAuthorization({ dispatch, getState }) {
  // Same format - we do this to have the Redux State available.
  // The difference is that this time we also pull in the helper
  // `getState` which will allow us to.....
  // ....
  // get the state.
  //
  return (nextState, replace, next) => {
    // reference to the `client` piece of state
    const client = getState().client;

    // is it defined and does it have a token? good, go ahead to widgets
    if (client && client.token) return next();

    // not set yet?  Let's try and set it and if so, go ahead to widgets
    if (checkAuthorization(dispatch)) return next();

    // nope?  okay back to login ya go.
    replace('signin');
    return next();
  };
}

export const getDeviceName = () => {
  const userAgent = navigator.userAgent;

  let deviceName = '';

  if (userAgent.match(/Android/)) {
    deviceName = 'Android';
  } else if (userAgent.match(/iPhone/)) {
    deviceName = 'iPhone';
  } else if (userAgent.match(/iPad/)) {
    deviceName = 'iPad';
  } else if (userAgent.match(/Macintosh/)) {
    deviceName = 'Mac';
  } else {
    deviceName = 'Web';
  }

  return deviceName;
};

export const getDeviceVersion = () => {
  let deviceVersion = '';

  const userAgent = navigator.userAgent;
  const regex = /\(.*?([0-9]+(?:(?:\.|-)[0-9]+)*)/;
  const match = userAgent.match(regex);

  if (match && match.length > 1) {
    deviceVersion = match[1];
  }

  return deviceVersion;
};

export const getOSType = () => {
  const osType = navigator.platform;

  return osType;
};

export const getDeviceId = () => {
  let deviceId = '';

  if (navigator.hardwareConcurrency) {
    deviceId = navigator.hardwareConcurrency.toString();
  } else {
    deviceId = navigator.productSub;
  }

  return deviceId;
};

export const getTimeZone = () => {
  let timeZone = '';
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return timeZone;
};

export const getDeviceIMEI = () => {
  const userAgent = navigator.userAgent;

  return userAgent;
};
