import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// const WrapperTextField = styled(TextField)`
//   width: 100%;

//   .MuiInputLabel-root {
//     font-size: 14px;
//     line-height: 17.64px;
//     font-family: outfit_medium;
//     transform: none;
//     color: #292d32;
//     width: 100%;
//   }

//   .MuiInputLabel-root.Mui-focused {
//     color: #292d32;
//   }

//   .MuiInputLabel-root.Mui-disabled {
//     color: #292d32; /* Custom color for disabled label */
//   }

//   .MuiFormControl-root {
//     position: relative;
//   }

//   .MuiInput-root {
//     border: none;
//     border-bottom: 1px solid #efe3d7;
//     border-radius: 0px;
//     ${(props) =>
//       !props.multiline &&
//       `
// 			height: ${props.height ? `${props.height}px` : '50px'};
// 			padding: 15px;
// 		`}
//     font-family: outfit_regular;
//     font-size: 16px;
//     line-height: 16px;
//     color: #292d32;

//     ${(props) =>
//       props.bgColor &&
//       `
// 	 	    background-color:  ${props.theme[props.bgColor]};
// 	    `}

//     ${(props) =>
//       props.borderNone &&
//       `
// 	 	    border: none;
// 	    `}

// 		&:before, &:after {
//       border: none !important;
//     }

//     &.MuiInputBase-root-MuiInput-root.Mui-disabled {
//         opacity: 1;
//         cursor: not-allowed;
//         color: #292d32 !important;
//     }

//     .MuiInputBase-input {
//       color: #292d32;
//       font-size: 16px;
//       font-weight: 400;
//       line-height: 16px;
//       padding: ${(props) => (props.multiline ? '15px' : '0')};

//       &::placeholder {
//         color: #292d32; /* Your placeholder color here */
//         opacity: 1; /* Ensures full visibility */
//       }

//       ${(props) =>
//         props.multiline &&
//         `
// 				min-height: ${props.height ? `${props.height}px` : '80px'}; /* For multiline, define a min height */
// 				line-height: 1.5; /* Better line spacing for multiline content */
// 				overflow-y:auto !important;
// 			    max-height: ${props.height ? `${props.height}px !important` : '80px'};
// 			`}
//     }
//     &.Mui-disabled {
//       opacity: 1;
//       cursor: not-allowed;
//     }
//   }
// `;

const WrapperTextField = styled(TextField)`
  width: 100%;

  .MuiInputLabel-root {
    font-size: 14px;
    line-height: 17.64px;
    font-family: outfit_medium;
    transform: none;
    color: #292d32;
    width: 100%;
  }

  .MuiInputLabel-root.Mui-focused {
    color: #292d32;
  }

  .MuiInputLabel-root.Mui-disabled {
    color: #292d32 !important; /* Ensure label changes color */
  }

  .MuiFormControl-root {
    position: relative;
  }

  .MuiInput-root {
    border: none;
    border-bottom: 1px solid #efe3d7;
    border-radius: 0px;
    ${(props) =>
      !props.multiline &&
      `
			height: ${props.height ? `${props.height}px` : '50px'};
			padding: 15px;
		`}
    font-family: outfit_regular;
    font-size: 16px;
    line-height: 16px;
    color: #292d32;

    ${(props) =>
      props.bgColor &&
      `
	 	    background-color:  ${props.theme[props.bgColor]};
	    `}

    ${(props) =>
      props.borderNone &&
      `
	 	    border: none;
	    `}

		&:before,
    &:after {
      border: none !important;
    }

    & .Mui-disabled {
      opacity: 1;
      cursor: not-allowed;
      -webkit-text-fill-color: #292d32 !important;
    }

    .MuiInputBase-input {
      color: #292d32;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      padding: ${(props) => (props.multiline ? '15px' : '0')};

      &::placeholder {
        color: #292d32; /* Custom placeholder color */
        opacity: 1; /* Full visibility */
      }

      ${(props) =>
        props.multiline &&
        `
				min-height: ${props.height ? `${props.height}px` : '80px'};
				line-height: 1.5;
				overflow-y: auto !important;
			    max-height: ${props.height ? `${props.height}px !important` : '80px'};
			`}
    }
    & .MuiInputBase-input-MuiInput-input.Mui-disabled {
      -webkit-text-fill-color: rgb(134, 70, 41) !important;
    }
  }
`;

const WrapperErrorDiv = styled.div`
  font-size: 16px;
  color: red;
  line-height: 24px;
  font-family: outfit_regular;
  margin-top: 5px;
  position: absolute;
  bottom: -23px;
`;

const WrapperHelperDiv = styled.div`
  color: #292d32;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter_Regular;
`;

function LabelWithInput({ containerProps, ...props }) {
  return (
    <FormControl fullWidth>
      <WrapperTextField
        id="standard-required"
        variant="standard"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        multiline={props.multiline}
        {...props}
      />

      {props.errorText && <WrapperErrorDiv>{props.errorText}</WrapperErrorDiv>}

      {props.helperInfoPassword && (
        <WrapperHelperDiv>
          <div>
            <FormattedMessage id="register.helperText" />
          </div>
        </WrapperHelperDiv>
      )}
    </FormControl>
  );
}

LabelWithInput.defaultProps = {
  fontSize: 16,
  textColor: '#292d32',
};

export default LabelWithInput;
