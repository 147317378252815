import React from 'react';

const AboutUs = () => {
  return (
    <>
      <p>AboutUs</p>
      <p>AboutUs details</p>
    </>
  );
};

export default AboutUs;
