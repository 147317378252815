import { useState } from 'react';
import Router from 'routes/Router';
import { Provider } from 'react-redux';
import store from 'store';
import UserContextProvider from 'context/UserContextProvider';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import TranslationsEn from 'utils/locale/englishTranslation';
import TranslationsAr from 'utils/locale/arabicTranslation';
import LocalStorageManager from 'managers/LocalStorageManger';

function loadMessages(locale) {
  switch (locale) {
    case 'en':
      return TranslationsEn;
    case 'ar':
      return TranslationsAr;
    default:
      return TranslationsEn;
  }
}

function getDirection(locale) {
  switch (locale) {
    case 'en':
      return 'ltr';
    case 'ar':
      return 'rtl';
    default:
      return 'ltr';
  }
}
const App = () => {
  const [locale, setLocale] = useState('en');
  const [direction, setDirection] = useState(getDirection('en'));
  const [messages, setMessages] = useState(loadMessages('en'));

  const handleLaguageChange = (val) => {
    setLocale(val);
    setDirection(getDirection(val));
    setMessages(loadMessages(val));
  };

  LocalStorageManager.setItem('locale', locale);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <UserContextProvider>
        <Provider store={store}>
          <div dir={direction}>
            <BrowserRouter>
              <Router LanguageSwitcher={(val) => handleLaguageChange(val)} />
            </BrowserRouter>
          </div>
        </Provider>
      </UserContextProvider>
    </IntlProvider>
  );
};

export default App;
