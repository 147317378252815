import axios from 'axios';

// Action Types
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

// Action Creators
export const loginUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const loginUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users,
});

export const loginUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});

// Thunk Function
export const loginUsers = () => {
  return async (dispatch) => {
    dispatch(loginUsersRequest());
    try {
      const response = await axios.get(
        'https://jsonplaceholder.typicode.com/users'
      );
      dispatch(loginUsersSuccess(response.data));
    } catch (error) {
      dispatch(loginUsersFailure(error.message));
    }
  };
};
