import axios from 'axios';
import { getMethod, postMethod, putMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const REGISTER_ONE_REQUEST = 'REGISTER_ONE_REQUEST';
export const REGISTER_ONE_SUCCESS = 'REGISTER_ONE_SUCCESS';
export const REGISTER_ONE_FAILURE = 'REGISTER_ONE_FAILURE';

// Action Creators
export const registerOneRequest = () => ({
  type: REGISTER_ONE_REQUEST,
});

export const registerOneSuccess = (users) => ({
  type: REGISTER_ONE_SUCCESS,
  payload: users,
});

export const registerOneFailure = (error) => ({
  type: REGISTER_ONE_FAILURE,
  payload: error,
});

// Thunk Function

export const registerOnePost = (body, userId, external) => {
  return async (dispatch) => {
    dispatch(registerOneRequest());
    try {
      const response = await putMethod(
        url.postRegisterStepOne + userId + '/',
        body
      );
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
          dispatch(registerOneSuccess(response));
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(registerOneFailure(error));
      if (external?.error === true) {
        external?.handleErrorFunction(error);
      }
    }
  };
};
