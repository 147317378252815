import AboutUs from 'Pages/AboutUs';
import Dashboard from 'Pages/Dashboard';
import LandingPage from 'Pages/LandingScreen';
import Login from 'Pages/Login';
import OtpVerificationScreen from 'Pages/OtpVerificationScreen';
import RegisterMetghod from 'Pages/Register';
import RegistrationStepOne from 'Pages/RegistrationStepOne';
import {
  login,
  dashboard,
  aboutUs,
  otpMethod,
  register,
  registrationOne,
} from 'routes/AppUrls';

const publicRoutes = [
  {
    path: (locale) => login(locale),
    element: <Login />,
    isNoSidebar: true,
  },
  {
    path: (locale) => otpMethod(locale),
    element: <OtpVerificationScreen />,
    isNoSidebar: true,
  },
  {
    path: (locale) => register(locale),
    element: <RegisterMetghod />,
    isNoSidebar: true,
  },
  {
    path: (locale) => registrationOne(locale),
    element: <RegistrationStepOne />,
    isNoSidebar: true,
  },
];

const privateRoutes = [
  {
    path: (locale) => dashboard(locale),
    element: <Dashboard />,
    isNoSidebar: false,
    isPrivate: true,
    allowedRoles: ['User', 'Admin'],
  },
];

const commonRoutes = [
  {
    path: (locale) => aboutUs(locale),
    element: <AboutUs />,
    isNoSidebar: true,
    isCommon: true,
    isPrivate: true,
  },
];

export const allRoutes = [...publicRoutes, ...privateRoutes, ...commonRoutes];
