import React, { useContext, useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import 'assets/styles/common.scss';
import LabelWithInput from 'components/commom/LabelWithInput';
import ReusableTabs from 'components/commom/Tab';
import SwitchInput from 'components/commom/SwitchInput';
import InputAdornment from '@mui/material/InputAdornment';
import SelectInput from 'components/commom/SelectInput';
import { useIntl } from 'react-intl';
import { LocaleContext } from 'routes/Router';
import Button from '@mui/material/Button';
import MailIcon from 'assets/images/mail.svg';
import PhoneIcon from 'assets/images/phone-icon.svg';
import { useNavigate } from 'react-router-dom';
import { registrationOne } from 'routes/AppUrls';
import { useDispatch, useSelector } from 'react-redux';
import { registerUsers } from 'store/register/registerAction';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getCity } from 'store/getCity/getCityAction';
import { registerStartPost } from 'store/registerGetStart/registerGetStartAction';
import CheckValidation from 'utils/validate';
import ToastNotification from 'components/commom/Toast';

const RegisterMetghod = () => {
  const intl = useIntl();
  const [conf, setConf] = ToastNotification();
  const { loading, users, error } = useSelector(
    (state) => state?.registerUserReducer
  );
  const cityData = useSelector((state) => state?.getCityReducer?.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [formData, setFormData] = useState({
    providerName: '',
    email: '',
    mobileNumber: '',
    state: '',
    city: '',
    stateId: '',
    cityId: '',
    phone: '',
  });
  console.log(conf);
  const [isError, setIsError] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event?.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChange = (event) => {
    const external = {
      run: true,
      handleFunction: (res) => {},
      error: true,
      handleErrorFunction: (res) => {},
    };
    const { name, value } = event?.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value?.name,
      stateId: name === 'state' ? value?.id : prev.stateId,
      cityId: name === 'city' ? value?.id : name === 'state' ? '' : prev.cityId,
      ...(name === 'state' && { city: '' }),
    }));

    if (name === 'state') {
      dispatch(getCity(tab, value?.id, external));
    }
  };

  const handlePhoneChange = (value, data) => {
    const dialCode = `+${data.dialCode}`;
    let mobileNumber = value.trim();

    if (mobileNumber.startsWith(dialCode)) {
      mobileNumber = mobileNumber.slice(dialCode.length).trim();
    }
    setFormData((prev) => ({
      ...prev,
      ['phone']: mobileNumber,
    }));
    setPhone(mobileNumber);
    setCountryCode(data.dialCode);
  };

  useEffect(() => {
    dispatch(registerUsers(tab));
  }, []);

  const tabData = [
    { label: intl.formatMessage({ id: 'United States of America' }) },
    { label: intl.formatMessage({ id: 'Jamaica' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
    dispatch(registerUsers(val));
    setFormData(() => ({
      ['city']: '',
      ['state']: '',
    }));
  };

  const hanldeRegister = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setConf({ msg: res?.message, variant: 'success' });
        navigate(registrationOne(locale));
      },
      error: true,
      handleFailerFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    const error = CheckValidation(formData, phone);
    setIsError(error);
    let body = {
      full_name: formData?.providerName,
      country_code: `+${countryCode}`,
      contact_number: phone,
      email: formData?.email,
      address: {
        country: tab,
        state: formData?.stateId,
        city: formData?.cityId,
      },
    };
    dispatch(registerStartPost(body, external));
  };
  return (
    <Row className="m-0 p-0 landing-image">
      <Row className="m-0 p-0">
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={12}
          xl={10}
          xxl={10}
          className="m-auto"
        >
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              xxl={4}
              className="my-auto"
            >
              <div className="f-40 lh-52 family_outfit_semiBold_600 ">
                Landing Page
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              xl={7}
              xxl={7}
              className="bg-light-100 sm:m-auto mb-20 sm:mb-0 mt-md-3"
            >
              <div class="p-24">
                <p className="main-heading p-0 m-0 family_outfit_semiBold_600">
                  Get Started
                </p>
                <p className="fs-14 family_outfit_regular_400 lh-16 fc-7F7F7F pt-8 m-0">
                  Rutrum arcu mauris diam ultrices id aliquet turpis.
                </p>
                <Row>
                  <Col sm={12} className="pt-32">
                    <LabelWithInput
                      label="Name"
                      placeholder="Provider Name"
                      name="providerName"
                      type="text"
                      value={formData?.providerName}
                      onChange={(e) => handleInputChange(e)}
                      errorText={
                        isError &&
                        isError?.providerName?.error &&
                        isError?.providerName?.errorMessage
                      }
                    />
                  </Col>
                  <Col sm={12} className="pt-20">
                    <p className="fs-18 lh-23 fc-3C3C3C family_outfit_semiBold_600 p-0 m-0">
                      Contact Details
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6} xxl={6} className="pt-12">
                    <LabelWithInput
                      label="Email ID"
                      placeholder="Email ID"
                      name="email"
                      type="text"
                      value={formData?.email}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Image
                              src={MailIcon}
                              alt="icon"
                              style={{ cursor: 'pointer' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      errorText={
                        isError &&
                        isError?.email?.error &&
                        isError?.email?.errorMessage
                      }
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6} xxl={6} className="pt-8">
                    <div>
                      <label
                        htmlFor="phone-input"
                        className="fs-14 family_outfit_regular_400 lh-176 fc-3C3C3C"
                      >
                        Mobile Number
                      </label>
                      <PhoneInput
                        id="phone-input"
                        country={'us'}
                        inputProps={{
                          name: 'phone',
                          required: true,
                        }}
                        name={'phone'}
                        value={phone}
                        onChange={handlePhoneChange}
                        placeholder="Enter phone number"
                        inputClass="custom-input-register"
                        containerClass="custom-container-register"
                        errorText={
                          isError &&
                          isError?.phone?.error &&
                          isError?.phone?.errorMessage
                        }
                      />
                      <div
                        className="fs-16 family_outfit_regular_400"
                        style={{
                          color: 'red',
                        }}
                      >
                        {isError &&
                        isError?.phone?.error &&
                        isError?.phone?.errorMessage
                          ? 'field is required!'
                          : ''}
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} className="pt-20">
                    <p className="fs-18 lh-23 fc-3C3C3C family_outfit_semiBold_600 p-0 m-0">
                      Location of Practice
                    </p>
                  </Col>
                  <Col sm={12} className="pt-8">
                    <p className="fs-14 lh-176 fc-292D32 family_outfit_regular_400 p-0 m-0">
                      Country
                    </p>
                  </Col>
                  <Col md={12} lg={12} xl={12} xxl={8} className="pt-04">
                    <ReusableTabs
                      tabs={tabData}
                      defaultTab={1}
                      currentTab={(val) => handleTabChange(val)}
                    />
                  </Col>
                  <Row>
                    <Col md={6} lg={6} xl={6} xxl={6} className="pt-16">
                      <p className="fs-14 lh-176 fc-292D32 family_outfit_regular_400">
                        State
                      </p>
                      <SelectInput
                        placeholder={intl.formatMessage({
                          id: 'State',
                        })}
                        name={'state'}
                        value={formData?.state}
                        data={users?.data}
                        onChange={(e) => handleChange(e)}
                        errorText={
                          isError &&
                          isError?.state?.error &&
                          isError?.state?.errorMessage
                        }
                      />
                    </Col>
                    <Col md={6} lg={6} xl={6} xxl={6} className="pt-16">
                      <p className="fs-14 lh-176 fc-292D32 family_outfit_regular_400">
                        City
                      </p>
                      <SelectInput
                        placeholder={intl.formatMessage({
                          id: 'City',
                        })}
                        name={'city'}
                        value={formData?.city}
                        data={cityData?.data}
                        onChange={(e) => handleChange(e)}
                        disabled={!formData?.state?.length}
                        errorText={
                          isError &&
                          isError?.city?.error &&
                          isError?.city?.errorMessage
                        }
                      />
                    </Col>
                  </Row>
                </Row>
                <div className="sm:pt-32 mt-190 sm:mt-0">
                  <Button
                    variant="contained"
                    className="button w-100"
                    onClick={() => hanldeRegister()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default RegisterMetghod;
