import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import 'assets/styles/common.scss';
import AbsolutLogo from 'assets/images/absolute-logo.svg';
import AbsoluteLogoMobile from 'assets/images/absolute-logo-phone.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Button from '@mui/material/Button';
import ArrowBack from 'assets/images/arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import { otpMethod, register } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { loginUsers } from 'store/login/LoginAction';
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
  const dispatch = useDispatch();
  const { loading, users, error } = useSelector((state) => state?.userReducer);
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    dispatch(loginUsers());
  }, [dispatch]);

  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const hanldeLogin = () => {
    navigate(otpMethod(locale));
  };
  const handleRegister = () => {
    navigate(register(locale));
  };
  return (
    <>
      <Row className="m-0 p-0 landing-image">
        <Row className="m-0 p-0 flex justify-content-center">
          <Col
            xs={12}
            sm={10}
            md={10}
            lg={6}
            xl={6}
            xxl={4}
            className="bg-gray-100 sm:bg-light-100 m-sm-auto m-lg-auto"
          >
            <div class="p-15 sm:p-32">
              <div className="d-none d-sm-flex justify-content-center">
                <Image src={AbsolutLogo} alt={'icon'} />
              </div>
              <div className="d-flex d-sm-none justify-content-between">
                <div>
                  <Image src={ArrowBack} alt={'icon'} />
                </div>
                <div>
                  <Image src={AbsoluteLogoMobile} alt={'icon'} />
                </div>
              </div>
              <div className="main-heading pt-8 sm:pt-24 family_outfit_semiBold_600">
                Login
              </div>
              <div className="main-description pt-12 fs-16 family_outfit_regular_400 lh-208 fc-998A7B">
                Please enter your details to sign in
              </div>
              <div>
                <label
                  htmlFor="phone-input"
                  className="pt-24 sm:pt-40 fs-14 family_outfit_regular_400 lh-176 fc-3C3C3C"
                >
                  Mobile Number
                </label>
                <PhoneInput
                  id="phone-input"
                  country={'us'}
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Enter phone number"
                  inputClass="custom-input"
                  containerClass="custom-container"
                />
              </div>
              <div className="sm:pt-28 mt-190 sm:mt-0">
                <Button
                  variant="contained"
                  className="button w-100"
                  onClick={() => hanldeLogin()}
                >
                  Login
                </Button>
              </div>
              <div className="pt-40 flex justify-center fs-18 family_outfit_regular_400 lh-27 fc-7F7F7F">
                Don’t have an account?
              </div>
              <div
                className="pt-04 flex justify-center fs-20 family_outfit_medium_500 fc-3C3C3C lh-30 cursor-pointer"
                onClick={() => handleRegister()}
              >
                Register
              </div>
            </div>
          </Col>
        </Row>
      </Row>
    </>
  );
};
export default Login;
