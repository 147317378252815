import React from 'react';

const Dashboard = () => {
  return (
    <>
      <p>Dashboard</p>
      <p>Dashboard details</p>
    </>
  );
};

export default Dashboard;
