import { EmailValidation, PhoneNumberValidation } from 'utils/helper';
import { FormattedMessage } from 'react-intl';

const CheckValidation = (value) => {
  const formError = {
    submit: true,
    providerName: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    email: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    mobileNumber: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    state: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    city: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    stateId: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!!" />,
    },
    cityId: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    phone: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
  };

  const emptyKeys = [];
  for (let key in value) {
    emptyKeys.push(key);
  }
  for (let i = 0; i < emptyKeys.length; i++) {
    if (!value[emptyKeys[i]]) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
    }

    if (
      value[emptyKeys[i]] &&
      emptyKeys[i] === 'email' &&
      !EmailValidation(value[emptyKeys[i]])
    ) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
      formError[emptyKeys[i]].errorMessage = (
        <FormattedMessage id="Please Enter Valid Email!" />
      );
    }
  }
  return formError;
};

export default CheckValidation;
