import { combineReducers } from 'redux';
import userReducer from 'store/user/userReducer';
import registerUserReducer from './register/registerReducer';
import getCityReducer from './getCity/getCityReducer';
import registerStartReducer from './registerGetStart/registerGetStartReducer';
import registerUploadReducer from './registerUpload/registerUploadReducer';
import registerProviderTypeReducer from './registerProviderType/registerProviderTypeReducer';
import registerTitleReducer from './registerTitle/registerTitleReducer';
import registerQualificationReducer from './registerQualification/registerQualificationReducer';
import registerOneReducer from './registerStepOne/registerStepOneReducer';
import getReviewDetailReducer from './registerReview/registerReviewReducer';

export default combineReducers({
  userReducer,
  registerUserReducer,
  getCityReducer,
  registerStartReducer,
  registerUploadReducer,
  registerProviderTypeReducer,
  registerTitleReducer,
  registerQualificationReducer,
  registerOneReducer,
  getReviewDetailReducer,
});
